<div class="main-content">
  <div class="page-content" #pageContent>
    <div
      class="buttons-container"
      [ngClass]="{
        'no-scroll': pageContent.offsetHeight > pageContent.scrollHeight
      }"
    >
      <div class="action-buttons">
        <div>
          <button class="btn btn-danger" (click)="router.navigateByUrl('/')">
            <i class="fa-solid fa-list-ul"></i
            ><span>{{ 'event.retour' | translate }}</span>
          </button>
          <button
            class="btn btn-success"
            (click)="
              router.navigateByUrl(
                '/stats/' +
                  currentEvent.customer +
                  '/' +
                  currentEvent.device +
                  '/' +
                  currentEvent.start_date
              )
            "
          >
            <i class="fa-solid fa-chart-line"></i
            ><span>{{ 'stats.stats' | translate }}</span>
          </button>
        </div>

        <button
          class="btn btn-success"
          *ngIf="!editionMode"
          (click)="
            router.navigateByUrl(
              '/event/edit/' +
                currentEvent.customer +
                '/' +
                currentEvent.device +
                '/' +
                currentEvent.start_date.replaceAll('-', '')
            )
          "
        >
          <i class="fa-solid fa-pen-to-square"></i
          ><span>{{ 'event.editer' | translate }}</span>
        </button>

        <div *ngIf="editionMode" class="d-flex">
          <button
            class="btn btn-danger"
            (click)="
              router.navigateByUrl(
                '/event/' +
                  currentEvent.customer +
                  '/' +
                  currentEvent.device +
                  '/' +
                  currentEvent.start_date.replaceAll('-', '')
              )
            "
          >
            <i class="fa-solid fa-arrow-rotate-left"></i
            ><span>{{ 'event.annuler' | translate }}</span></button
          ><button class="btn btn-success" (click)="onSaveEvent()">
            <i class="fa-solid fa-floppy-disk"></i
            ><span>{{ 'event.sauvegarder' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
    <div
      class="event-details"
      *ngIf="
        currentEvent && currentEvent.customer == manager.currentUser.customer
      "
    >
      <h2>{{ currentEvent.event_name }}</h2>

      <div class="row">
        <div class="col-12 col-xl-6">
          <div class="form-group">
            <label for="device">{{ 'event.device' | translate }} : </label>
            <input name="device" [ngModel]="currentEvent.device" readonly />
          </div>
          <div class="form-group">
            <label for="debut">{{ 'event.debut' | translate }} : </label>
            <input name="debut" [ngModel]="currentEvent.start_date" readonly />
          </div>
          <div class="form-group">
            <label for="fin">{{ 'event.fin' | translate }} : </label>
            <input
              name="fin"
              [ngModel]="currentEvent.end_date ? currentEvent.end_date : '-'"
              readonly
            />
          </div>
          <div class="form-group">
            <label for="lang">{{ 'event.langueDefaut' | translate }} : </label>
            <input
              name="lang"
              [ngModel]="currentEvent.default_lang"
              readonly
              *ngIf="!editionMode"
            />
            <select
              class="form-select"
              [(ngModel)]="currentEvent.default_lang"
              *ngIf="editionMode"
            >
              <option *ngFor="let lang of manager.langs" [value]="lang">
                {{ lang }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="keepData"
              >{{ 'event.dureeConservation' | translate }} :
            </label>
            <input
              name="keepData"
              [ngModel]="currentEvent.keeping_duration_data"
              readonly
            />
          </div>
          <div class="form-group">
            <label for="broadcastScreen"
              >{{ 'event.ecranDiffusion' | translate }} :
            </label>
            <input
              name="broadcastScreen"
              [ngModel]="currentEvent.broadcast_screen"
              readonly
            />
          </div>
          <div class="form-group">
            <label for="gif">GIF : </label>
            <input name="gif" [ngModel]="currentEvent.gif" readonly />
          </div>
          <div class="form-group">
            <label for="colorfilters"
              >{{ 'event.filtresColores' | translate }} :
            </label>
            <input
              name="colorfilters"
              [ngModel]="currentEvent.color_filters"
              readonly
            />
          </div>
          <div class="form-group" *ngIf="false">
            <label for="fancyfilters"
              >{{ 'event.filtresFantaisie' | translate }} :
            </label>
            <input
              name="fancyfilters"
              [ngModel]="currentEvent.fancy_filters"
              readonly
            />
          </div>
          <div
            class="form-group"
            *ngIf="
              currentEvent.fancy_filters == 'oui' &&
              currentEvent.active_filters.length > 0
            "
          >
            <span>{{ 'event.filtresFantaisieActives' | translate }} : </span>
            <ul>
              <li *ngFor="let filter of currentEvent.active_filters">
                {{ filter }}
              </li>
            </ul>
          </div>
          <div>
            <div class="title-group">{{ 'event.horaires' | translate }} :</div>
            <ul class="calendar-list">
              <li
                *ngFor="
                  let calendar of currentEvent.power_calendar;
                  let i = index
                "
              >
                <div class="d-flex align-items-center">
                  <div class="day">{{ days[i] }}</div>
                  <ngb-timepicker
                    [ngClass]="{
                      invalidTime: calendar.power_on_hour == null
                    }"
                    [(ngModel)]="calendar.power_on_hour"
                    [disabled]="!editionMode || !editionCalendar[i]"
                    [size]="'small'"
                  ></ngb-timepicker>
                  /
                  <ngb-timepicker
                    [(ngModel)]="calendar.shutdown_hour"
                    [disabled]="!editionMode || !editionCalendar[i]"
                    [ngClass]="{
                      invalidTime: calendar.shutdown_hour == null
                    }"
                    [size]="'small'"
                  ></ngb-timepicker>

                  <button
                    class="calendar-btn"
                    (click)="editionCalendar[i] = !editionCalendar[i]"
                    *ngIf="editionMode"
                  >
                    <i
                      *ngIf="!editionCalendar[i]"
                      class="fa-solid fa-pen-to-square"
                    ></i>
                    <i
                      *ngIf="editionCalendar[i]"
                      class="fa-solid fa-floppy-disk"
                    ></i>
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-xl-6">
          <div>
            <div class="title-group">
              {{ 'event.imageCadre' | translate }} :
            </div>
            <div class="d-flex">
              <img
                #frameDisplay
                class="frame-display"
                [src]="
                  newFrameFile && newFrameFile.fileContent
                    ? newFrameFile.fileContent
                    : 'https://files.photocall-v2.dreamtronic.net/Frames/' +
                      currentEvent.customer +
                      '/' +
                      currentEvent.device +
                      '/' +
                      currentEvent.start_date.replaceAll('-', '') +
                      '/' +
                      currentEvent.frame_file
                "
                *ngIf="
                  (newFrameFile && newFrameFile.fileContent) ||
                  currentEvent.frame_file
                "
              />

              <div *ngIf="editionMode">
                <label for="frame-file-upload" class="custom-file-upload">
                  <i class="fa-solid fa-download"></i>
                </label>
                <input
                  id="frame-file-upload"
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  (change)="displayFileUploaded($event, 'Frame')"
                />
              </div>
            </div>
          </div>
          <div>
            <div class="title-group">{{ 'event.corpsMail' | translate }} :</div>
            <textarea
              name="emailBody"
              class="flex-fill w-100"
              [ngClass]="{ invalidInput: currentEvent.email_body == '' }"
              [(ngModel)]="currentEvent.email_body"
              [readonly]="!editionMode"
            ></textarea>
          </div>
          <div>
            <div class="title-group">
              {{ 'event.textePerso' | translate }}
              <span
                class="btn-add"
                *ngIf="
                  editionMode &&
                  currentEvent.optionnal_text_translations.length <
                    manager.langs.length
                "
              >
                <button (click)="onAddTranslation('customText')">
                  <i class="fa-solid fa-plus"></i></button
              ></span>
              :
              <span
                *ngIf="
                  !currentEvent.optionnal_text_translations ||
                  currentEvent.optionnal_text_translations.length == 0
                "
              >
                {{ 'event.na' | translate }}
              </span>
            </div>

            <ul *ngIf="currentEvent.optionnal_text_translations.length > 0">
              <li
                *ngFor="let lang of currentEvent.optionnal_text_translations"
                class="d-flex"
              >
                <div class="w-100">
                  <div class="form-group">
                    <label>{{ 'event.langue' | translate }} : </label>
                    <input
                      [(ngModel)]="lang.lang"
                      readonly
                      *ngIf="!editionMode"
                    />
                    <select
                      class="form-select"
                      [ngClass]="{
                        invalidInput: !lang.lang
                      }"
                      [(ngModel)]="lang.lang"
                      *ngIf="editionMode"
                    >
                      <option
                        *ngFor="let lang of manager.langs"
                        [value]="lang"
                        [disabled]="isLangAlreadyUsed(lang, 'CustomText')"
                      >
                        {{ lang }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="customTextValue"
                      >{{ 'event.texte' | translate }} :
                    </label>
                    <div class="flex-fill">
                      <input
                        [ngClass]="{
                          invalidInput:
                            !lang.value ||
                            !onCheckTextLength(lang.value, 'customText')
                        }"
                        class="w-100 input-select"
                        name="customTextValue"
                        [(ngModel)]="lang.value"
                        [readonly]="!editionMode"
                      />
                      <span
                        class="error-message"
                        *ngIf="!onCheckTextLength(lang.value, 'customText')"
                      >
                        {{ 'event.texteTropLong' | translate }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="btn-delete" *ngIf="editionMode">
                  <button (click)="deleteTranslation('CustomText', lang.lang)">
                    <i class="fa-solid fa-trash"></i>
                  </button>
                </div>
              </li>
            </ul>
          </div>

          <div>
            <div class="title-group">
              {{ 'event.texteNewsletter' | translate }}
              <span
                class="btn-add"
                *ngIf="
                  editionMode &&
                  currentEvent.newsletter_custom_text_translations.length <
                    manager.langs.length
                "
                ><button (click)="onAddTranslation('customNewsletter')">
                  <i class="fa-solid fa-plus"></i></button
              ></span>
              :
              <span
                *ngIf="
                  !currentEvent.newsletter_custom_text_translations ||
                  currentEvent.newsletter_custom_text_translations.length == 0
                "
              >
                {{ 'event.na' | translate }}
              </span>
            </div>

            <ul
              *ngIf="
                currentEvent.newsletter_custom_text_translations.length > 0
              "
            >
              <li
                class="d-flex"
                *ngFor="
                  let lang of currentEvent.newsletter_custom_text_translations
                "
              >
                <div class="w-100">
                  <div class="form-group">
                    <label>{{ 'event.langue' | translate }} : </label>
                    <input
                      [ngModel]="lang.lang"
                      readonly
                      *ngIf="!editionMode"
                    />
                    <select
                      class="form-select"
                      [ngClass]="{
                        invalidInput: !lang.lang
                      }"
                      [(ngModel)]="lang.lang"
                      *ngIf="editionMode"
                    >
                      <option
                        *ngFor="let lang of manager.langs"
                        [value]="lang"
                        [disabled]="isLangAlreadyUsed(lang, 'NewsletterText')"
                      >
                        {{ lang }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="newsletterCustomTextLang"
                      >{{ 'event.texte' | translate }} :
                    </label>
                    <div class="flex-fill">
                      <input
                        [ngClass]="{
                          invalidInput:
                            !lang.value ||
                            !onCheckTextLength(lang.value, 'newsletterText')
                        }"
                        class="w-100 input-select"
                        name="newsletterCustomTextLang"
                        [(ngModel)]="lang.value"
                        [readonly]="!editionMode"
                      />
                      <span
                        class="error-message"
                        *ngIf="!onCheckTextLength(lang.value, 'newsletterText')"
                      >
                        {{ 'event.texteTropLong' | translate }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="btn-delete" *ngIf="editionMode">
                  <button (click)="deleteTranslation('Newsletter', lang.lang)">
                    <i class="fa-solid fa-trash"></i>
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div *ngIf="currentEvent.broadcast_screen == 'oui'">
            <div class="form-group">
              <label for="broadcastBgColor"
                >{{ 'event.couleurFond' | translate }} :
              </label>
              <input
                class="color-input"
                [(colorPicker)]="currentEvent.broadcast_screen_background_color"
                [style.background]="
                  currentEvent.broadcast_screen_background_color
                "
                [cpOutputFormat]="'hex'"
                [cpDisabled]="!editionMode"
                [cpPosition]="'auto'"
                readonly
              />
            </div>

            <div>
              <div class="title-group">
                {{ 'event.imageFond' | translate }} :
              </div>
              <div class="d-flex">
                <img
                  class="broadcast-background-display"
                  [ngStyle]="{
                    'background-color':
                      currentEvent.broadcast_screen_background_color
                  }"
                  [src]="
                    newBgFile && newBgFile.fileContent
                      ? newBgFile.fileContent
                      : 'https://files.photocall-v2.dreamtronic.net/Background/' +
                        currentEvent.customer +
                        '/' +
                        currentEvent.device +
                        '/' +
                        currentEvent.start_date.replaceAll('-', '') +
                        '/' +
                        currentEvent.broadcast_screen_background_image
                  "
                  *ngIf="
                    currentEvent.broadcast_screen_background_image ||
                    (newBgFile && newBgFile.fileContent)
                  "
                />

                <div *ngIf="editionMode">
                  <label for="bg-file-upload" class="custom-file-upload">
                    <i class="fa-solid fa-download"></i>
                  </label>
                  <input
                    id="bg-file-upload"
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    (change)="displayFileUploaded($event, 'Bg')"
                  />
                </div>
              </div>
            </div>
            <div>
              <div class="title-group">
                {{ 'event.modeDiffusion' | translate }} :
              </div>
              <input
                class="w-100"
                name="broadcastAlgo"
                *ngIf="!editionMode"
                [ngModel]="
                  currentEvent.broadcast_screen_days_algo == 'oui'
                    ? 'Captures prises au cours des ' +
                      manager.projectConfigs.nb_last_days_broadcast +
                      ' derniers jours'
                    : currentEvent.broadcast_screen_nb_captures_algo == 'oui'
                    ? manager.projectConfigs.nb_last_captures_broadcast +
                      ' dernières captures prises'
                    : '-'
                "
                readonly
              />

              <select
                class="form-select"
                [(ngModel)]="broadcastAlgo"
                *ngIf="editionMode"
              >
                <option value="days">
                  {{
                    'event.derniersJours'
                      | translate
                        : { nb: manager.projectConfigs.nb_last_days_broadcast }
                  }}
                </option>
                <option value="nb">
                  {{
                    'event.dernieresCaptures'
                      | translate
                        : {
                            nb: manager.projectConfigs
                              .nb_last_captures_broadcast
                          }
                  }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div #customNewsletterApercu class="customNewsletterApercu"></div>
<div #customApercu class="customApercu"></div>

<!-- MODALE -->
<ng-template #modaleErrorTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'event.erreur' | translate }}
    </h4>
  </div>

  <div class="modal-body mt-2">
    <p>
      {{ 'event.erreurSauvegarde' | translate }}
    </p>
    <p *ngIf="uploadError">{{ 'event.telechargementEchoue' | translate }}</p>
    <p>{{ 'event.veuillezReessayer' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="modal.dismiss()">
      {{ 'event.ok' | translate }}
    </button>
  </div>
</ng-template>
