import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { ManagerService } from '../services/manager.service';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.scss'],
})
export class ConnexionComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private manager: ManagerService
  ) {}

  incorrectPassword: boolean = false;

  ngOnInit(): void {
    this.authService.signIn();
  }

  onConnect(form: NgForm) {
    this.authService
      .signIn(form.value['email'], form.value['password'])
      .then((valid) => {
        this.incorrectPassword = !valid;
      });
  }
}
