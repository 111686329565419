import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConnexionComponent } from './connexion/connexion.component';
import { EventComponent } from './event/event.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeComponent } from './home/home.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthGuard } from './services/auth-guard.service';
import { StatsComponent } from './stats/stats.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: 'stats/:customer/:device/:startDate',
    component: StatsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'event/:customer/:device/:startDate',
    component: EventComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'event/edit/:customer/:device/:startDate',
    component: EventComponent,
    canActivate: [AuthGuard],
  },
  { path: 'forgottenPassword', component: ForgotPasswordComponent },
  { path: 'resetPassword/:resetKey', component: ResetPasswordComponent },
  { path: 'auth', component: ConnexionComponent },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
