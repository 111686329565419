import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiManagerService } from '../services/api-manager.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  email!: string;
  errorUpdate: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiManagerService
  ) {}

  ngOnInit(): void {
    this.api
      .GetAccountByResetKey(this.route.snapshot.params['resetKey'])
      .then((account) => {
        if (account && account.email) {
          this.email = account.email;
        } else {
          this.router.navigateByUrl('/auth');
        }
      });
  }

  onUpdatePassword(form: NgForm) {
    this.errorUpdate = false;

    if (this.email && form.value['password']) {
      this.api
        .UpdatePassword(this.email, form.value['password'])
        .then((res) => {
          if (res.updated) {
            this.router.navigateByUrl('/auth');
          } else {
            this.errorUpdate = true;
          }
        });
    }
  }
}
