<div class="main-content">
  <div class="loading-spinner" *ngIf="!isTableLoaded">
    <div class="spinner-border" role="status"></div>
  </div>
  <table
    datatable
    #datatable
    [dtOptions]="dtOptions"
    [dtTrigger]="dtTrigger"
    class="row-border hover"
    [ngClass]="{ 'd-none': !isTableLoaded }"
  >
    <thead>
      <tr>
        <th>{{ 'home.nomEvent' | translate | uppercase }}</th>
        <th>{{ 'home.dateDebut' | translate | uppercase }}</th>
        <th>{{ 'home.dateFin' | translate | uppercase }}</th>
        <th>{{ 'home.captures' | translate | uppercase }}</th>
        <th>{{ 'home.emails' | translate | uppercase }}</th>
        <th>{{ 'home.actions' | translate | uppercase }}</th>
        <th>{{ 'home.device' | translate | uppercase }}</th>
      </tr>
    </thead>
    <tbody *ngIf="events?.length != 0">
      <tr *ngFor="let event of events">
        <td>{{ event.event_name }}</td>
        <td>{{ event.start_date }}</td>
        <td>{{ event.end_date ? event.end_date : '-' }}</td>
        <td>
          {{
            convert(event.stats_files.nb_img) +
              convert(event.stats_files.nb_gif)
          }}
        </td>
        <td>{{ event.stats_emails.nb_emails }}</td>
        <td>
          <button
            placement="bottom"
            [ngbTooltip]="translate.get('home.voir') | async"
            class="table-action-button"
            (click)="
              router.navigateByUrl(
                '/event/' +
                  manager.currentUser.customer +
                  '/' +
                  event.device +
                  '/' +
                  event.start_date
              )
            "
          >
            <i class="fa-solid fa-eye"></i>
          </button>
          <button
            placement="bottom"
            [ngbTooltip]="translate.get('home.editer') | async"
            class="table-action-button"
            (click)="
              router.navigateByUrl(
                '/event/edit/' +
                  manager.currentUser.customer +
                  '/' +
                  event.device +
                  '/' +
                  event.start_date
              )
            "
          >
            <i class="fa-solid fa-pen-to-square"></i></button
          ><button
            placement="bottom"
            [ngbTooltip]="translate.get('home.stats') | async"
            class="table-action-button"
            (click)="
              router.navigateByUrl(
                '/stats/' +
                  manager.currentUser.customer +
                  '/' +
                  event.device +
                  '/' +
                  event.start_date
              )
            "
          >
            <i class="fa-solid fa-chart-line"></i>
          </button>
        </td>
        <td>{{ event.device }}</td>
      </tr>
    </tbody>
  </table>
</div>
