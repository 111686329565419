import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ManagerService } from '../services/manager.service';

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  langSubscription!: Subscription;
  langValues!: any;

  constructor(
    private translate: TranslateService,
    private manager: ManagerService
  ) {
    super();
    this.updateLangValues();

    this.langSubscription = this.manager.langSubject.subscribe((lang) => {
      this.updateLangValues();
    });
  }

  getWeekdayLabel(weekday: number): string {
    return this.langValues.weekdays[weekday - 1];
  }
  override getWeekLabel(): string {
    return this.langValues.weekLabel;
  }
  getMonthShortName(month: number): string {
    return this.langValues.months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  updateLangValues(): Promise<null> {
    return new Promise((resolve) => {
      this.translate.get('datepicker').subscribe((translations) => {
        this.langValues = translations;
        resolve(null);
      });
    });
  }
}
