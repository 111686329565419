<div class="main-content">
  <div class="page-content" #pageContent>
    <div
      class="buttons-container"
      [ngClass]="{
        'no-scroll': pageContent.offsetHeight > pageContent.scrollHeight
      }"
    >
      <div class="action-buttons">
        <button
          class="btn btn-danger btn-return"
          (click)="router.navigateByUrl('/')"
        >
          <i class="fa-solid fa-list-ul"></i
          ><span>{{ 'stats.retour' | translate }}</span>
        </button>

        <div class="d-flex">
          <button
            class="btn btn-danger d-flex align-items-center"
            (click)="
              !downloadLoaded &&
                filesOnSelectedPeriod.length > 0 &&
                downloadImages()
            "
            [disabled]="filesOnSelectedPeriod.length == 0"
          >
            <div
              class="loading-spinner d-flex align-items-center"
              *ngIf="downloadLoaded"
            >
              <div class="spinner-border" role="status"></div>
            </div>
            <i class="fa-solid fa-download" *ngIf="!downloadLoaded"></i
            ><span>{{ 'stats.photos' | translate | uppercase }}</span
            ><span class="nb-badge" *ngIf="!downloadLoaded">{{
              filesOnSelectedPeriod.length
            }}</span
            ><span
              class="nb-badge"
              *ngIf="downloadLoaded && nbFilesDownloaded > 0"
              >{{ nbFilesDownloaded + ' / ' + filesToDownload.length }}</span
            >
            <span
              class="nb-badge"
              *ngIf="downloadLoaded && compressionPercent > 0"
              >{{ compressionPercent + '%' }}</span
            ></button
          ><button
            class="btn btn-success d-flex align-items-center"
            (click)="emailsOnSelectedPeriod.length > 0 && downloadEmails()"
            [disabled]="emailsOnSelectedPeriod.length == 0"
          >
            <i class="fa-solid fa-download"></i
            ><span>{{ 'stats.emails' | translate | uppercase }}</span
            ><span class="nb-badge">{{ emailsOnSelectedPeriod.length }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="stats-details">
      <div class="row dateSelection">
        <div class="col-12 d-flex justify-content-center">
          <app-range-date-picker
            #datePicker
            [startDate]="startDate"
            [endDate]="currentEvent ? currentEvent.end_date : null"
            class="d-flex justify-content-center"
          ></app-range-date-picker>
        </div>
      </div>
      <div class="row">
        <div class="msg" *ngIf="datePicker.outOfBounds">
          {{ 'stats.evenementPasDemarre' | translate }}
        </div>
        <div class="col-12 stats-captures" *ngIf="statsCaptures">
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="stat-box row">
                <div class="col-12 stat-box-nb">
                  {{ this.statsCaptures.total }}
                </div>
                <div class="col-12 stat-box-title">
                  {{ 'stats.nbimg' | translate }}
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="stat-box row">
                <div class="col-12 stat-box-nb">
                  {{ this.statsCaptures.nb_img }}
                </div>
                <div class="col-12 stat-box-title">
                  {{ 'stats.nbphotos' | translate }}
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="stat-box row">
                <div class="col-12 stat-box-nb">
                  {{ this.statsCaptures.nb_gif }}
                </div>
                <div class="col-12 stat-box-title">
                  {{ 'stats.nbgif' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 stats-emails" *ngIf="statsEmails">
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="stat-box row">
                <div class="col-12 stat-box-nb">
                  {{ this.statsEmails.nb_emails }}
                </div>
                <div class="col-12 stat-box-title">
                  {{ 'stats.nbemails' | translate }}
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="stat-box row">
                <div class="col-12 stat-box-nb">
                  {{ this.statsEmails.nb_distinct_emails }}
                </div>
                <div class="col-12 stat-box-title">
                  {{ 'stats.nbemailsdistincts' | translate }}
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="stat-box row">
                <div class="col-12 stat-box-nb">
                  {{ this.statsEmails.nb_emails_newsletter }}
                </div>
                <div class="col-12 stat-box-title">
                  {{ 'stats.nbemailsnews' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MODALE -->
<ng-template #modaleAlert let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'stats.telechargement' | translate }}
    </h4>
  </div>

  <div class="modal-body mt-2">
    <p>{{ 'stats.telechargementlance' | translate }}</p>
    <p>
      {{ 'stats.veuillezRester' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="modal.dismiss()">
      {{ 'stats.ok' | translate | uppercase }}
    </button>
  </div>
</ng-template>
