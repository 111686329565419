import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { LangMenuComponent } from './lang-menu/lang-menu.component';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('langMenu') langMenu!: LangMenuComponent;

  constructor(
    private translate: TranslateService,
    public auth: AuthService,
    private cookieService: CookieService
  ) {
    this.translate.setDefaultLang('fr');
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.langMenu.onSelectLang(
      this.cookieService.get('defaultLang')
        ? this.cookieService.get('defaultLang')
        : this.translate.defaultLang
    );
  }
}
