import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { promises } from 'dns';
import { env } from 'process';
import { environment } from '../../environments/environment';
import { Account } from '../models/account.model';
import { PhotocallEvent } from '../models/photocallEvent.model';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class ApiManagerService {
  httpOptions: any;

  constructor(private httpClient: HttpClient, private utils: UtilsService) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Token: environment.apiToken,
      }),
    };
  }

  /**
   * Vérifie si un coupe email / mot de passe sont corrects
   * @param {string} email email
   * @param {string} password mot de passe
   * @param {boolean} encrypted if password parameter is encrypted
   * @returns {Promise}
   */
  CheckCredentials(
    email: string,
    password: string,
    encrypted: boolean
  ): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient
        .post<any>(
          environment.apiURL +
            'customer_bo_account' +
            (encrypted ? '/encrypted' : ''),
          { email: email, password: password },
          <object>this.httpOptions
        )
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  /**
   * Récupère les infos d'un compte client par son emails
   * @param {string} email Email du compte
   * @returns {Promise} Promise avec objet du compte
   */
  GetAccountInfo(email: string): Promise<Account> {
    return new Promise((resolve) => {
      this.httpClient
        .get<Account>(
          environment.apiURL + 'customer_bo_account/' + email,
          <object>this.httpOptions
        )
        .subscribe((account) => {
          resolve(account);
        });
    });
  }

  /**
   * Récupère les valeurs de configuration globales du projet Photocall
   * @returns {Promise} Promise avec objet contentn les valuers de config
   */
  GetConfigs(): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient
        .get<Account>(environment.apiURL + 'config', <object>this.httpOptions)
        .subscribe((configs) => {
          resolve(configs);
        });
    });
  }

  /**
   * Met à jour le mot de passe d'un compte client
   * @param {String} email email du compte à mettre à jour
   * @param {String} newPass nouveau mot de passe
   * @returns {Promise} Promise avec objet de réponse
   */
  UpdatePassword(email: string, newPass: string): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient
        .put<any>(
          environment.apiURL + 'customer_bo_account',
          { email: email, password: newPass },
          <object>this.httpOptions
        )
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  /**
   * Enregistre une resetKey pour un compte
   * @param {String} accountEmail email du compte à mettre à jour
   * @param {String} resetKey valeur de la resetKey
   * @returns {Promise} Promise avec objet de réponse
   */
  SetResetKey(accountEmail: string, resetKey: string): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient
        .put<any>(
          environment.apiURL + 'customer_bo_account',
          { email: accountEmail, resetKey: resetKey },
          <object>this.httpOptions
        )
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  /**
   * Récupère un compte grâce à sa resetKey
   * @param {String} resetKey valeur de la resetKey
   * @returns {Promise} Promise avec compte associé à la resetKey
   */
  GetAccountByResetKey(resetKey: string): Promise<Account> {
    return new Promise<Account>((resolve) => {
      this.httpClient
        .get<Account>(
          environment.apiURL + 'customer_bo_account/resetkey/' + resetKey,
          <object>this.httpOptions
        )
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  /**
   * Envoi d'un email avec le lien de réinitialisation de mot de passe
   * @param {String} email email destinataire
   * @param {String} resetKey valeur de la resetKey
   * @returns {Promise} Promise avec objet de réponse
   */
  SendEmailResetKey(
    email: string,
    resetKey: string,
    subject: string,
    body: string
  ): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient
        .post<any>(
          environment.apiFilesURL + 'sendResetLink',
          { email: email, resetKey: resetKey, subject: subject, body: body },
          <object>this.httpOptions
        )
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  /**
   * Récupère la liste des événements d'un client
   * @param {number} customer ID du client
   * @returns {Promise} Promise avec la liste des événements
   */
  GetEventsOfCustomer(customer: number): Promise<PhotocallEvent[]> {
    return new Promise((resolve) => {
      this.httpClient
        .get<PhotocallEvent[]>(
          environment.apiURL + '/event/customer/' + customer,
          <object>this.httpOptions
        )
        .subscribe((events) => {
          resolve(events);
        });
    });
  }

  /**
   * Récupère les stats d'emails sur la durée complète de l'événement
   * @param {PhotocallEvent} event Objet de l'événement
   * @param {number} customer ID du client
   * @returns {Promise} Promise avec objet contenant les données statistiques
   */
  GetStatsEmailsOnAllPeriod(
    event: PhotocallEvent,
    customer: number
  ): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient
        .get<any>(
          environment.apiURL +
            'stats_emails/period/event/' +
            event.start_date +
            '/' +
            (event.end_date
              ? event.end_date
              : this.utils.FormatDate(new Date())) +
            '/' +
            customer +
            '/' +
            event.device +
            '/' +
            event.start_date,
          <object>this.httpOptions
        )
        .subscribe((events) => {
          resolve(events);
        });
    });
  }

  /**
   * Récupère les stats d'images sur la durée complète de l'événement
   * @param {PhotocallEvent} event Objet de l'événement
   * @param {number} customer ID du client
   * @returns {Promise} Promise avec objet contenant les données statistiques
   */
  GetStatsFilesOnAllPeriod(
    event: PhotocallEvent,
    customer: number
  ): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient
        .get<any>(
          environment.apiURL +
            '/stats_file/period/event/' +
            event.start_date +
            '/' +
            (event.end_date
              ? event.end_date
              : this.utils.FormatDate(new Date())) +
            '/' +
            customer +
            '/' +
            event.device +
            '/' +
            event.start_date,
          <object>this.httpOptions
        )
        .subscribe((events) => {
          resolve(events);
        });
    });
  }

  /**
   * Récupère les détails d'un événement
   * @param {string} customer ID du client
   * @param {string} device N° de série du device
   * @param {string} startDate Date de début de l'event (yyyy-mm-dd)
   * @returns {Promise} Promise avec l'objet de l'event
   */
  GetEventData(
    customer: string,
    device: string,
    startDate: string
  ): Promise<PhotocallEvent> {
    return new Promise<PhotocallEvent>((resolve) => {
      this.httpClient
        .get<any>(
          environment.apiURL +
            '/event/' +
            customer +
            '/' +
            device +
            '/' +
            startDate,
          <object>this.httpOptions
        )
        .subscribe((events) => {
          resolve(events);
        });
    });
  }

  /**
   * Met à jour un event
   * @param {PhotocallEvent} event Objet de l'événement
   * @returns {Promise} Promise avc objet de réponse
   */
  SaveEvent(event: PhotocallEvent): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient
        .put<any>(environment.apiURL + 'event', event, <object>this.httpOptions)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  /**
   * Upload un fichier sur le serveur de fichier
   * @param {'Frame' | 'Bg'} type catégorie de fichier
   * @param {string} folder dossier du fichier
   * @param {string} fileName nom du fichier
   * @param {string} content data du fichier
   * @returns {Promise} Promise avec objet de réponse
   */
  UploadFileOnServer(
    type: 'Frame' | 'Bg',
    folder: string,
    fileName: string,
    content: any
  ): Promise<any> {
    return new Promise<any>((resolve) => {
      this.httpClient
        .post<any>(
          environment.apiFilesURL +
            'UploadFile/' +
            (type == 'Frame' ? 'Frames' : 'Background') +
            '/' +
            folder,
          { file_name: fileName, file_content: content }
        )
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  /**
   * Récupère la liste des fichiers capturés sur un event donné
   * @param {string} customer ID du client
   * @param {string} device N° de série du device
   * @param {string} startDate Date de début de l'event (yyyy-mm-dd)
   * @returns {promise} Promise avec la liste des fichiers et leur propriétés
   */
  GetListFilesOfEvent(
    customer: string,
    device: string,
    startDate: string
  ): Promise<any[]> {
    return new Promise((resolve) => {
      this.httpClient
        .get<any[]>(
          environment.apiURL +
            'file/' +
            customer +
            '/' +
            device +
            '/' +
            startDate.replaceAll('-', ''),
          <object>this.httpOptions
        )
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  /**
   * Récupère la liste des emails ayant autorisé la newsletter pour un event donné
   * @param {string} customer ID du client
   * @param {string} device N° de série du device
   * @param {string} startDate Date de début de l'event (yyyy-mm-dd)
   * @returns {promise} Promise avec la liste des emails et leur date de saisie
   */
  GetEmailsAllowNewsletter(
    customer: string,
    device: string,
    startDate: string
  ): Promise<any[]> {
    return new Promise((resolve) => {
      this.httpClient
        .get<any[]>(
          environment.apiURL +
            'email/event/newsletter/' +
            customer +
            '/' +
            device +
            '/' +
            startDate,
          <object>this.httpOptions
        )
        .subscribe((emails) => {
          resolve(emails);
        });
    });
  }

  /**
   * Récupère les stats d'images' d'un évent sur une période donnée
   * @param {string} customer ID du client
   * @param {string} device N° de série du device
   * @param {string} startDate Date de début de l'event (yyyy-mm-dd)
   * @param {string} filterStart Date de début de la période (yyyy-mm-dd)
   * @param {string} filterEnd Date de fin de la période (yyyy-mm-dd)
   * @returns
   */
  GetCapturesStats(
    customer: string,
    device: string,
    startDate: string,
    filterStart: string,
    filterEnd: string
  ): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient
        .get<string[]>(
          environment.apiURL +
            'stats_file/period/event/' +
            filterStart +
            '/' +
            filterEnd +
            '/' +
            customer +
            '/' +
            device +
            '/' +
            startDate,
          <object>this.httpOptions
        )
        .subscribe((stats) => {
          resolve(stats);
        });
    });
  }

  /**
   * Récupère les stats d'emais d'un évent sur une période donnée
   * @param {string} customer ID du client
   * @param {string} device N° de série du device
   * @param {string} startDate Date de début de l'event (yyyy-mm-dd)
   * @param {string} filterStart Date de début de la période (yyyy-mm-dd)
   * @param {string} filterEnd Date de fin de la période (yyyy-mm-dd)
   * @returns
   */
  GetEmailsStats(
    customer: string,
    device: string,
    startDate: string,
    filterStart: string,
    filterEnd: string
  ): Promise<any> {
    return new Promise((resolve) => {
      this.httpClient
        .get<string[]>(
          environment.apiURL +
            'stats_emails/period/event/' +
            filterStart +
            '/' +
            filterEnd +
            '/' +
            customer +
            '/' +
            device +
            '/' +
            startDate,
          <object>this.httpOptions
        )
        .subscribe((stats) => {
          resolve(stats);
        });
    });
  }
}
