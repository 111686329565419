import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbModal,
  NgbModalRef,
  NgbTimeAdapter,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ImageFile } from '../models/imageFile.model';
import { PhotocallEvent } from '../models/photocallEvent.model';
import { ApiManagerService } from '../services/api-manager.service';
import { ManagerService } from '../services/manager.service';
import { NgbTimeStringAdapter } from '../services/ngbTimeStringAdapter.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }],
})
export class EventComponent implements OnInit {
  @ViewChild('customApercu') customApercu!: ElementRef;
  @ViewChild('customNewsletterApercu') customNewsletterApercu!: ElementRef;
  @ViewChild('modaleErrorTemplate')
  modaleErrorTemplate!: TemplateRef<ElementRef>;

  newFrameFile!: any;
  newBgFile!: any;

  currentEvent!: PhotocallEvent;
  editionMode: boolean = false;

  modalError!: NgbModalRef;
  uploadError: boolean = false;

  broadcastAlgo!: string;

  editionCalendar: boolean[] = [];
  days: string[] = [];

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private api: ApiManagerService,
    public manager: ManagerService,
    private modalService: NgbModal,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.get('global.days').subscribe((trad) => {
      this.days = trad;
    });

    this.editionMode = this.router.url.includes('edit');

    this.api
      .GetEventData(
        this.route.snapshot.params['customer'],
        this.route.snapshot.params['device'],
        this.route.snapshot.params['startDate']
      )
      .then((event) => {
        if (
          event.customer &&
          event.device &&
          event.start_date &&
          event.customer == this.manager.currentUser.customer
        ) {
          this.currentEvent = event;
          this.broadcastAlgo =
            this.currentEvent.broadcast_screen_days_algo == 'oui'
              ? 'days'
              : this.currentEvent.broadcast_screen_nb_captures_algo == 'oui'
              ? 'nb'
              : '';

          this.initCalendarEdition();
        } else {
          this.router.navigateByUrl('/');
        }
      });
  }

  ngAfterViewInit() {}

  initCalendarEdition() {
    this.currentEvent.power_calendar.forEach((cal) => {
      this.editionCalendar.push(false);
    });
  }

  // formatPowerCalendarToSave(): CalendarDay[] {
  //   let formatedToSaveCalendar: CalendarDay[] = [];

  //   this.formatedCalendar.forEach((calendar) => {
  //     formatedToSaveCalendar.push({
  //       day_index: calendar.day,
  //       power_on_hour:
  //         (calendar.power_on_hour.hour < 10
  //           ? '0' + calendar.power_on_hour.hour
  //           : calendar.power_on_hour.hour) +
  //         ':' +
  //         (calendar.power_on_hour.minute < 10
  //           ? '0' + calendar.power_on_hour.minute
  //           : calendar.power_on_hour.minute),
  //       shutdown_hour:
  //         (calendar.shutdown_hour.hour < 10
  //           ? '0' + calendar.shutdown_hour.hour
  //           : calendar.shutdown_hour.hour) +
  //         ':' +
  //         (calendar.shutdown_hour.minute < 10
  //           ? '0' + calendar.shutdown_hour.minute
  //           : calendar.shutdown_hour.minute),
  //     });
  //   });

  //   return formatedToSaveCalendar;
  // }

  onAddTranslation(type: string) {
    if (type == 'customText') {
      if (
        this.currentEvent.optionnal_text_translations.filter(
          (trans) => !trans.lang
        ).length == 0
      ) {
        this.currentEvent.optionnal_text_translations.push({
          lang: '',
          value: '',
        });
      }
    } else {
      if (type == 'customNewsletter') {
        if (
          this.currentEvent.newsletter_custom_text_translations.filter(
            (trans) => !trans.lang
          ).length == 0
        ) {
          this.currentEvent.newsletter_custom_text_translations.push({
            lang: '',
            value: '',
          });
        }
      }
    }
  }

  onCheckTextLength(
    text: string,
    type: 'customText' | 'newsletterText'
  ): boolean {
    const maxWidth = type == 'customText' ? 1070 : 590;

    $(
      type == 'customText'
        ? this.customApercu.nativeElement
        : this.customNewsletterApercu.nativeElement
    ).text(text);

    let resp =
      Number(
        $(
          type == 'customText'
            ? this.customApercu.nativeElement
            : this.customNewsletterApercu.nativeElement
        ).innerWidth()
      ) < maxWidth;

    return resp;
  }

  displayFileUploaded(event: any, type: 'Frame' | 'Bg') {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const extension = file.name.split('.')[file.name.split('.').length - 1];

      if (extension == 'jpeg' || extension == 'jpg' || extension == 'png') {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (type == 'Frame') {
            this.newFrameFile = new ImageFile(
              event.target.files[0].name,
              reader.result
            );
          } else {
            this.newBgFile = new ImageFile(
              event.target.files[0].name,
              reader.result
            );
          }
        };
        reader.readAsDataURL(file);
      } else {
        if (type == 'Frame') {
          this.newFrameFile = null;
        } else {
          this.newBgFile = null;
        }
      }
    } else {
      if (type == 'Frame') {
        this.newFrameFile = null;
      } else {
        this.newBgFile = null;
      }
    }
  }

  deleteTranslation(type: 'CustomText' | 'Newsletter', lang: string) {
    if (type == 'CustomText') {
      this.currentEvent.optionnal_text_translations =
        this.currentEvent.optionnal_text_translations.filter(
          (trans) => trans.lang != lang
        );
    } else {
      this.currentEvent.newsletter_custom_text_translations =
        this.currentEvent.newsletter_custom_text_translations.filter(
          (trans) => trans.lang != lang
        );
    }
  }

  onSaveEvent() {
    if (this.validEvent(this.currentEvent)) {
      let liPromisesUpload = [];
      this.uploadError = false;

      if (this.newFrameFile) {
        liPromisesUpload.push(
          this.api
            .UploadFileOnServer(
              'Frame',
              this.currentEvent.customer +
                '_' +
                this.currentEvent.device +
                '_' +
                this.currentEvent.start_date.replaceAll('-', ''),
              this.newFrameFile.fileName,
              this.newFrameFile.fileContent.replace(
                /^data:image\/(png|jpg|jpeg);base64,/,
                ''
              )
            )
            .then((res) => {
              if (!res.created) {
                this.uploadError = true;
              } else {
                if (res.file_name) {
                  this.newFrameFile.fileName = res.file_name;
                  this.currentEvent.frame_file = res.file_name;
                }
              }
            })
        );
      }

      if (this.newBgFile) {
        liPromisesUpload.push(
          this.api
            .UploadFileOnServer(
              'Bg',
              this.currentEvent.customer +
                '_' +
                this.currentEvent.device +
                '_' +
                this.currentEvent.start_date.replaceAll('-', ''),
              this.newBgFile.fileName,
              this.newBgFile.fileContent.replace(
                /^data:image\/(png|jpg|jpeg);base64,/,
                ''
              )
            )
            .then((res) => {
              if (!res.created) {
                this.uploadError = true;
              } else {
                if (res.file_name) {
                  this.newBgFile.fileName = res.file_name;
                  this.currentEvent.broadcast_screen_background_image =
                    res.file_name;
                }
              }
            })
        );
      }

      Promise.all(liPromisesUpload).then((res) => {
        if (this.uploadError) {
          this.onOpenModal(this.modaleErrorTemplate);
        } else {
          this.currentEvent.broadcast_screen_days_algo =
            this.broadcastAlgo == 'days' ? 'oui' : 'non';
          this.currentEvent.broadcast_screen_nb_captures_algo =
            this.broadcastAlgo == 'nb' ? 'oui' : 'non';

          this.api.SaveEvent(this.currentEvent).then((res) => {
            if (res.updated) {
              this.router.navigateByUrl(
                '/event/' +
                  this.currentEvent.customer +
                  '/' +
                  this.currentEvent.device +
                  '/' +
                  this.currentEvent.start_date.replaceAll('-', '')
              );
            } else {
              this.onOpenModal(this.modaleErrorTemplate);
            }
          });
        }
      });
    } else {
      this.onOpenModal(this.modaleErrorTemplate);
    }
  }

  validEvent(event: PhotocallEvent): boolean {
    let error = false;
    event.optionnal_text_translations.forEach((trans) => {
      if (
        !this.onCheckTextLength(trans.value, 'customText') ||
        !trans.lang ||
        !trans.value
      ) {
        error = true;
      }
    });

    event.newsletter_custom_text_translations.forEach((trans) => {
      if (
        !this.onCheckTextLength(trans.value, 'newsletterText') ||
        !trans.lang ||
        !trans.value
      ) {
        error = true;
      }
    });

    if (this.currentEvent.email_body == '') {
      error = true;
    }

    this.currentEvent.power_calendar.forEach((cal) => {
      if (
        cal.power_on_hour == ('' || null || undefined) ||
        cal.shutdown_hour == ('' || null || undefined)
      ) {
        error = true;
      }
    });

    return !error;
  }

  onOpenModal(content: TemplateRef<ElementRef>) {
    this.modalError = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'modale-error',
    });
  }

  isLangAlreadyUsed(
    lang: string,
    translationType: 'CustomText' | 'NewsletterText'
  ): boolean {
    return translationType == 'CustomText'
      ? this.currentEvent.optionnal_text_translations.filter(
          (trans) => trans.lang == lang
        ).length > 0
      : this.currentEvent.newsletter_custom_text_translations.filter(
          (trans) => trans.lang == lang
        ).length > 0;
  }
}
