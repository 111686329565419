<button
  type="button"
  class="navigation-btn"
  (click)="!disabledPrevious() && goToPreviousWeek()"
  [ngClass]="{ disabled: disabledPrevious() }"
>
  <i class="fa-solid fa-angle-left"></i>
</button>
<form
  class="row row-cols-sm-auto d-flex justify-content-center position-relative"
>
  <div class="dp-hidden position-absolute">
    <div class="input-group">
      <input
        name="datepicker"
        class="form-control"
        ngbDatepicker
        #datepicker="ngbDatepicker"
        [autoClose]="'outside'"
        (dateSelect)="onDateSelection($event)"
        [displayMonths]="1"
        [dayTemplate]="t"
        outsideDays="hidden"
        [startDate]="fromDate!"
        tabindex="-1"
        [minDate]="
          startDate
            ? formatDateToNgDate(startDate).after(calendar.getToday())
              ? calendar.getToday()
              : formatDateToNgDate(startDate)
            : formatDateToNgDate('2022-01-01')
        "
        [maxDate]="
          endDate
            ? formatDateToNgDate(endDate).before(calendar.getToday())
              ? formatDateToNgDate(endDate)
              : calendar.getToday()
            : calendar.getToday()
        "
        [disabled]="outOfBounds"
      />
      <ng-template #t let-date let-focused="focused">
        <span
          class="custom-day"
          [class.focused]="focused"
          [class.range]="isRange(date)"
          [class.faded]="isHovered(date) || isInside(date)"
          (mouseenter)="hoveredDate = date"
          (mouseleave)="hoveredDate = null"
        >
          {{ date.day }}
        </span>
      </ng-template>
    </div>
  </div>
  <div class="col-12">
    <div class="input-group">
      <button
        class="btn btn-outline-secondary"
        type="button"
        (click)="datepicker.toggle()"
      >
        <i class="fa-solid fa-calendar-days"></i>
      </button>
      <input
        #dpFromDate
        class="form-control"
        placeholder="dd/mm/yyyy"
        name="dpFromDate"
        [value]="formatter.format(fromDate)"
        (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
        readonly
        (click)="datepicker.toggle()"
      />
    </div>
  </div>
  <div class="col-12">
    <div class="input-group">
      <button
        class="btn btn-outline-secondary"
        type="button"
        (click)="datepicker.toggle()"
      >
        <i class="fa-solid fa-calendar-days"></i>
      </button>
      <input
        #dpToDate
        class="form-control"
        placeholder="dd/mm/yyyy"
        name="dpToDate"
        [value]="formatter.format(toDate)"
        (input)="toDate = validateInput(toDate, dpToDate.value)"
        readonly
        (click)="datepicker.toggle()"
      />
    </div>
  </div>
</form>
<button
  type="button"
  class="navigation-btn"
  (click)="!disabledNext() && goToNextWeek()"
  [ngClass]="{ disabled: disabledNext() }"
>
  <i class="fa-solid fa-angle-right"></i>
</button>
