import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Account } from '../models/account.model';
import { ApiManagerService } from './api-manager.service';

@Injectable({
  providedIn: 'root',
})
export class ManagerService {
  currentUser!: Account;
  langSubject: Subject<string> = new Subject<string>();
  projectConfigs: any;

  // Used to send data between rangeDatePicker and StatComponent
  endDateSubject: Subject<string | null> = new Subject();
  endDate!: string;

  constructor(private api: ApiManagerService) {
    this.api.GetConfigs().then((configs) => {
      this.projectConfigs = configs;
    });
  }

  langs = ['FR', 'EN', 'ES', 'DE', 'IT'];

  initAccount(email: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.api.GetAccountInfo(email).then((account) => {
        this.currentUser = account;
        resolve(true);
      });
    });
  }

  emitLangSubject(lang: string) {
    this.langSubject.next(lang);
  }

  emitEndDate(date: string | null) {
    if (date) {
      this.endDate = date;
    }
    this.endDateSubject.next(date ? date : this.endDate);
  }
}
