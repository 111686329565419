<div class="d-flex main-content">
  <div class="col-10 col-sm-8 col-lg-6 col-xl-5 form-box">
    <h2>{{ 'resetPass.titre' | translate }}</h2>
    <form (ngSubmit)="onUpdatePassword(f)" #f="ngForm">
      <div class="form-group">
        <label for="password"> {{ 'resetPass.nouveau' | translate }} </label>
        <input
          type="password"
          class="form-control"
          name="password"
          #passwordInput="ngModel"
          ngModel
          required
          password
          [ngClass]="{ invalidInput: passwordInput.errors?.['required'] && (passwordInput?.dirty || passwordInput?.touched) }"
        />
      </div>
      <div class="form-group">
        <label for="passwordConfirm">
          {{ 'resetPass.nouveauConfirm' | translate }}
        </label>
        <input
          type="password"
          class="form-control"
          name="passwordConfirm"
          #passwordConfirmInput="ngModel"
          ngModel
          required
          [ngClass]="{ invalidInput: (passwordConfirmInput.errors?.['required'] && (passwordConfirmInput?.dirty ||passwordConfirmInput?.touched)) || f.value['password'] !== f.value['passwordConfirm'] }"
        />
      </div>
      <div class="messages row d-flex justify-content-between">
        <div class="col-auto">
          <div
            class="error-text"
            *ngIf="f.value['password'] !== f.value['passwordConfirm']"
          >
            {{ 'resetPass.identiques' | translate }}
          </div>
          <div class="error-text" *ngIf="errorUpdate">
            {{ 'resetPass.erreurMAJ' | translate }}
          </div>
        </div>
      </div>

      <button
        class="btn btn-success mt-4"
        type="submit"
        [disabled]="
          f.invalid || f.value['password'] !== f.value['passwordConfirm']
        "
      >
        {{ 'resetPass.valider' | translate | uppercase }}
      </button>
    </form>
  </div>
</div>
