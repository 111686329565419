import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  /**
   * Formate une date au format YYYY-MM-DD
   * @param {Date} date Date à formater
   * @returns {String} La date formatée
   */
  FormatDate = (date: Date) => {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
      '0' + date.getDate()
    ).slice(-2)}`;
  };

  /**
   * Génère une chaine aléatoire composée de 25 caractères
   * @returns {string} ResetKey
   */
  GenerateResetKey = () => {
    let key = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (var i = 0; i < 25; i++) {
      key += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return key;
  };
}
