import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
  NgbDatepicker,
  NgbDatepickerI18n,
} from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ManagerService } from '../services/manager.service';
import { CustomDatepickerI18n } from './customdatepickeri18n';
import { NgbDateCustomParserFormatter } from './dateformat';

@Component({
  selector: 'app-range-date-picker',
  templateUrl: './range-date-picker.component.html',
  styleUrls: ['./range-date-picker.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
})
export class RangeDatePickerComponent implements OnInit {
  @Input() startDate!: string;
  @Input() endDate!: string | null;

  @ViewChild('datepicker') datePicker!: NgbDatepicker;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  hoveredDate: NgbDate | null = null;

  rangeUpdateSubject = new Subject<any>();

  outOfBounds: boolean = false;

  constructor(
    public calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private manager: ManagerService
  ) {
    let today = calendar.getToday();

    this.fromDate = this.getMondayOfCurrentWeek(today);
    this.toDate = calendar.getNext(this.fromDate, 'd', 6).after(today)
      ? today
      : calendar.getNext(this.fromDate, 'd', 6);

    this.emitRangeUpdate();

    this.manager.endDateSubject.subscribe((date) => {
      this.endDate = date;

      if (
        this.endDate &&
        this.calendar.getToday().after(this.formatDateToNgDate(this.endDate))
      ) {
        this.fromDate = this.getMondayOfCurrentWeek(
          this.formatDateToNgDate(this.endDate)
        );
        this.toDate = this.calendar
          .getNext(this.fromDate, 'd', 6)
          .after(this.formatDateToNgDate(this.endDate))
          ? this.formatDateToNgDate(this.endDate)
          : this.calendar.getNext(this.fromDate, 'd', 6);
      }
    });

    this.manager.emitEndDate(null);
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (
      this.calendar.getToday().before(this.formatDateToNgDate(this.startDate))
    ) {
      this.fromDate = null;
      this.toDate = null;
      this.outOfBounds = true;
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      this.toDate &&
      date &&
      date.before(this.fromDate)
    ) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      this.toDate &&
      date &&
      date.after(this.toDate)
    ) {
      this.toDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.equals(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.emitRangeUpdate();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  getMondayOfCurrentWeek(today: NgbDate) {
    return this.calendar.getWeekday(today) == 1
      ? today
      : this.calendar.getWeekday(today) == 0
      ? this.calendar.getPrev(today, 'd', 6)
      : this.calendar.getPrev(today, 'd', this.calendar.getWeekday(today) - 1);
  }

  formatDateToNgDate(date: string) {
    return new NgbDate(
      Number(date.split('-')[0]),
      Number(date.split('-')[1]),
      Number(date.split('-')[2])
    );
  }

  formatNgDateToString(date: NgbDate) {
    return (
      date.year +
      '-' +
      (date.month < 10 ? '0' + date.month : date.month) +
      '-' +
      (date.day < 10 ? '0' + date.day : date.day)
    );
  }

  emitRangeUpdate() {
    this.rangeUpdateSubject.next({
      startDate: this.fromDate,
      endDate: this.toDate,
    });
  }

  goToPreviousWeek() {
    const lastMonday = this.calendar.getPrev(
      this.getMondayOfCurrentWeek(
        this.fromDate ? this.fromDate : this.calendar.getToday()
      ),
      'd',
      7
    );
    const lastSunday = this.calendar.getNext(lastMonday, 'd', 6);

    this.fromDate = lastMonday.before(this.formatDateToNgDate(this.startDate))
      ? this.formatDateToNgDate(this.startDate)
      : lastMonday;
    this.toDate = lastSunday;

    this.emitRangeUpdate();
  }

  goToNextWeek() {
    const prevMonday = this.calendar.getNext(
      this.getMondayOfCurrentWeek(
        this.fromDate ? this.fromDate : this.calendar.getToday()
      ),
      'd',
      7
    );
    const lastSunday = this.calendar.getNext(prevMonday, 'd', 6);

    this.fromDate = prevMonday;
    this.toDate = lastSunday.after(this.calendar.getToday())
      ? this.calendar.getToday()
      : lastSunday;

    this.emitRangeUpdate();
  }

  disabledPrevious(): boolean {
    const formattedStartDate = this.formatDateToNgDate(this.startDate);
    const lastMonday = this.calendar.getPrev(
      this.getMondayOfCurrentWeek(
        this.fromDate ? this.fromDate : this.calendar.getToday()
      ),
      'd',
      7
    );
    if (lastMonday.before(formattedStartDate)) {
      let nextDay;
      for (let i = 0; i < 6; i++) {
        nextDay = this.calendar.getNext(lastMonday, 'd', i + 1);
        if (!nextDay.before(formattedStartDate)) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  disabledNext(): boolean {
    const nextMonday = this.calendar.getNext(
      this.getMondayOfCurrentWeek(
        this.fromDate ? this.fromDate : this.calendar.getToday()
      ),
      'd',
      7
    );
    return this.endDate &&
      this.formatDateToNgDate(this.endDate).before(this.calendar.getToday())
      ? nextMonday.after(this.formatDateToNgDate(this.endDate))
      : nextMonday.after(this.calendar.getToday());
  }
}
