import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiManagerService } from '../services/api-manager.service';
import { ManagerService } from '../services/manager.service';
import { Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  events: any[] = [];
  displayedEvents: any[] = [];
  filteredEvents: any[] = [];
  nbPerPage: number = 6;
  langSubscription!: Subscription;

  isTableLoaded: boolean = false;

  constructor(
    private api: ApiManagerService,
    public manager: ManagerService,
    public translate: TranslateService,
    public router: Router
  ) {
    this.langSubscription = this.manager.langSubject.subscribe((lang) => {
      this.isTableLoaded = false;
      this.initDatatable(lang);
    });
  }

  ngOnInit(): void {
    this.api
      .GetEventsOfCustomer(this.manager.currentUser.customer)
      .then((events) => {
        let liPromises = [] as Promise<any>[];

        events.forEach((event) =>
          liPromises.push(
            this.api
              .GetStatsEmailsOnAllPeriod(
                event,
                this.manager.currentUser.customer
              )
              .then((emailsStats) => {
                event.stats_emails = emailsStats;
              }),
            this.api
              .GetStatsFilesOnAllPeriod(
                event,
                this.manager.currentUser.customer
              )
              .then((filesStats) => {
                event.stats_files = filesStats;
              })
          )
        );

        Promise.all(liPromises).then((res) => {
          this.events = events;
          this.initDatatable(this.translate.currentLang);
        });
      });
  }

  initDatatable(lang: string) {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: this.nbPerPage,
      info: false,
      lengthChange: false,
      autoWidth: true,
      language: {
        url: '/assets/i18n/datatable-' + lang + '.json',
      },
      order: [[1, 'desc']],
      columns: [
        { name: 'Name' },
        { name: 'Début' },
        { name: 'Fin' },
        { name: 'Photos' },
        { name: 'Emails' },
        { name: 'Actions', orderable: false },
        { name: 'Appareil', visible: false, searchable: false },
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        $('td:nth-child(1)', row).off('click');
        $('td:nth-child(1)', row).on('click', () => {
          const dataAsArray = data as any[];
          this.router.navigateByUrl(
            '/stats/' +
              this.manager.currentUser.customer +
              '/' +
              dataAsArray[6] +
              '/' +
              dataAsArray[1]
          );
        });
        return row;
      },
    };
    setTimeout(() => {
      this.rerender();
    }, 100);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    try {
      this.dtElement.dtInstance
        .then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next(null);
          this.isTableLoaded = true;
        })
        .catch((e) => {
          this.dtTrigger.next(null);
          this.isTableLoaded = true;
        });
    } catch (e) {
      try {
        this.dtTrigger.next(null);
        this.isTableLoaded = true;
      } catch (e) {
        setTimeout(() => {
          this.rerender();
        }, 100);
      }
    }
  }

  convert(num: string) {
    return isNaN(Number(num)) ? 0 : Number(num);
  }
}
