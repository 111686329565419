import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';

import * as saveAs from 'file-saver';
import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PhotocallEvent } from '../models/photocallEvent.model';
import { RangeDatePickerComponent } from '../range-date-picker/range-date-picker.component';
import { ApiManagerService } from '../services/api-manager.service';
import { ManagerService } from '../services/manager.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
})
export class StatsComponent implements OnInit {
  customer!: string;
  device!: string;
  startDate!: string;

  currentEvent!: PhotocallEvent;

  downloadLoaded: boolean = false;
  filesToUploadURLs: any[] = [];

  @ViewChild('datePicker') datePicker!: RangeDatePickerComponent;

  rangeUpdateSubscription!: Subscription;
  filterStartDate!: NgbDate;
  filterEndDate!: NgbDate;

  statsCaptures: any;
  statsEmails: any;

  emailsOnSelectedPeriod: any[] = [];
  emailsOfAllPeriod: any[] = [];
  filesOnSelectedPeriod: any[] = [];
  filesOfAllEvent: any[] = [];

  filesToDownload: any[] = [];

  nbFilesDownloaded: number = 0;
  compressionPercent: number = 0;

  @ViewChild('modaleAlert') modaleAlertTemplate!: TemplateRef<ElementRef>;
  modaleAlert!: NgbModalRef;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private api: ApiManagerService,
    private manager: ManagerService,
    public formatter: NgbDateParserFormatter,
    private modaleService: NgbModal
  ) {
    this.device = this.route.snapshot.params['device'];
    this.startDate = this.route.snapshot.params['startDate'];
    this.customer = this.route.snapshot.params['customer'];

    if (this.manager.currentUser.customer.toString() != this.customer) {
      this.router.navigateByUrl('/');
    }

    this.api
      .GetEventData(this.customer, this.device, this.startDate)
      .then((event) => {
        this.currentEvent = event;
        this.manager.emitEndDate(this.currentEvent.end_date);
      });
  }

  ngOnInit(): void {
    this.api
      .GetListFilesOfEvent(this.customer, this.device, this.startDate)
      .then((res) => {
        this.filesOfAllEvent = res;
        this.datePicker.emitRangeUpdate();
      });

    this.api
      .GetEmailsAllowNewsletter(this.customer, this.device, this.startDate)
      .then((emails) => {
        this.emailsOfAllPeriod = emails;
        this.datePicker.emitRangeUpdate();
      });
  }

  ngAfterViewInit() {
    this.rangeUpdateSubscription = this.datePicker.rangeUpdateSubject.subscribe(
      (data) => {
        this.filterStartDate = data.startDate;
        this.filterEndDate = data.endDate;

        if (this.filterStartDate && this.filterEndDate) {
          this.api
            .GetCapturesStats(
              this.customer,
              this.device,
              this.startDate,
              this.datePicker.formatNgDateToString(this.filterStartDate),
              this.datePicker.formatNgDateToString(this.filterEndDate)
            )
            .then((statsImg) => {
              this.statsCaptures = statsImg;
              this.statsCaptures.total =
                Number(statsImg.nb_img) + Number(statsImg.nb_gif);
            });

          this.api
            .GetEmailsStats(
              this.customer,
              this.device,
              this.startDate,
              this.datePicker.formatNgDateToString(this.filterStartDate),
              this.datePicker.formatNgDateToString(this.filterEndDate)
            )
            .then((statsEmails) => {
              this.statsEmails = statsEmails;
            });

          this.filesOnSelectedPeriod = this.filesOfAllEvent.filter((file) => {
            let startDate = new Date(
              this.datePicker.formatNgDateToString(this.filterStartDate)
            );
            let endDate = new Date(
              this.datePicker.formatNgDateToString(this.filterEndDate)
            );

            return (
              startDate <= new Date(file.ts.split(' ')[0]) &&
              new Date(file.ts.split(' ')[0]) <= endDate
            );
          });

          this.emailsOnSelectedPeriod = this.emailsOfAllPeriod.filter(
            (email) => {
              let startDate = new Date(
                this.datePicker.formatNgDateToString(this.filterStartDate)
              );
              let endDate = new Date(
                this.datePicker.formatNgDateToString(this.filterEndDate)
              );
              return (
                startDate <= new Date(email.date) &&
                new Date(email.date) <= endDate
              );
            }
          );
        } else {
          this.statsEmails = null;
          this.statsCaptures = null;
        }
      }
    );

    this.datePicker.emitRangeUpdate();
  }

  downloadEmails() {
    let fileContent = '';
    this.emailsOnSelectedPeriod.forEach((email) => {
      fileContent += email.email + '\r\n';
    });
    var blob = new Blob([fileContent], {
      type: 'text/plain;charset=utf-8',
    });
    FileSaver.saveAs(
      blob,
      'photocall_emails_' +
        this.datePicker.formatNgDateToString(this.filterStartDate) +
        '_' +
        this.datePicker.formatNgDateToString(this.filterEndDate) +
        '.csv'
    );
  }

  downloadImages() {
    const that = this;

    this.modaleAlert = this.modaleService.open(this.modaleAlertTemplate, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'modale-download',
    });

    this.nbFilesDownloaded = 0;
    this.compressionPercent = 0;
    this.downloadLoaded = true;
    this.filesToDownload = this.filesOnSelectedPeriod.slice();

    this.filesToDownload.forEach((file) => {
      this.filesToUploadURLs.push(
        environment.apiFilesURL +
          'Captures_Files/' +
          this.customer +
          '/' +
          this.device +
          '/' +
          this.startDate.replaceAll('-', '') +
          '/' +
          file.file_name
      );
    });

    var zip = new JSZip();
    let liPromises: Promise<any>[] = [];

    this.filesToUploadURLs.forEach((file) => {
      liPromises.push(this.toDataURL(file));
    });

    Promise.all(liPromises).then((images) => {
      this.nbFilesDownloaded = 0;
      images.forEach((image) => {
        if (image) {
          zip.file(image.name, image.data, { base64: true });
        }
      });

      zip
        .generateAsync(
          {
            type: 'blob',
            compression: 'DEFLATE',
            compressionOptions: {
              level: 1,
            },
          },
          function updateCallback(metadata) {
            that.compressionPercent = Number(metadata.percent.toFixed(2));
          }
        )
        .then(function (content) {
          saveAs(
            content,
            'Photocall_' +
              that.datePicker.formatNgDateToString(that.filterStartDate) +
              (that.datePicker.formatNgDateToString(that.filterStartDate) !=
              that.datePicker.formatNgDateToString(that.filterEndDate)
                ? '_' + that.datePicker.formatNgDateToString(that.filterEndDate)
                : '') +
              '.zip'
          );
          that.downloadLoaded = false;
          that.modaleAlert.dismiss();
        });
    });
  }

  toDataURL(url: string): Promise<any> {
    const that = this;
    return new Promise((resolve) => {
      let xhRequest = new XMLHttpRequest();
      xhRequest.onload = function () {
        let reader = new FileReader();
        reader.onloadend = () => {
          that.nbFilesDownloaded++;
          resolve({
            name: url.split('/')[url.split('/').length - 1],
            data: reader.result
              ?.toString()
              .replace(/^data:image\/(png|gif);base64,/, ''),
          });
        };
        reader.readAsDataURL(xhRequest.response);
      };

      xhRequest.onerror = () => {
        console.log('erreur avec ' + url);
        resolve(null);
      };

      xhRequest.onabort = () => {
        console.log('abort avec ' + url);
        resolve(null);
      };

      xhRequest.open('GET', url);
      xhRequest.responseType = 'blob';
      xhRequest.send();
    });
  }
}
