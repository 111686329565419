import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ManagerService } from '../services/manager.service';

@Component({
  selector: 'app-lang-menu',
  templateUrl: './lang-menu.component.html',
  styleUrls: ['./lang-menu.component.scss'],
})
export class LangMenuComponent implements OnInit {
  isOpen: boolean = false;

  langs: any[] = [
    { name: 'fr', image: 'Francais.png', selected: true },
    { name: 'en', image: 'Anglais.png', selected: false },
    { name: 'es', image: 'Espagnol.png', selected: false },
    { name: 'it', image: 'Italien.png', selected: false },
    { name: 'de', image: 'Allemand.png', selected: false },
  ];

  constructor(
    private translate: TranslateService,
    private manager: ManagerService,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {}

  onDisplay() {
    this.isOpen = !this.isOpen;
  }

  onSelectLang(lang: string) {
    this.langs = this.langs.map((lg) => {
      lg.selected = lg.name == lang ? true : false;
      return lg;
    });
    this.translate.use(lang);
    this.cookieService.set('defaultLang', lang, { expires: 30, path: '/' });

    this.isOpen = false;
    this.manager.emitLangSubject(lang);
  }
}
