<div class="d-flex main-content">
  <div class="col-10 col-sm-8 col-lg-6 col-xl-5 form-box">
    <h2>{{ 'connexion.connexion' | translate }}</h2>
    <form (ngSubmit)="onConnect(f)" #f="ngForm">
      <div class="form-group">
        <label for="email"> {{ 'connexion.email' | translate }} </label>
        <input
          type="text"
          class="form-control"
          name="email"
          #emailInput="ngModel"
          ngModel
          required
          email
          [ngClass]="{ invalidInput: (emailInput.errors?.['required'] || emailInput.invalid)&& (emailInput?.dirty || emailInput?.touched) }"
        />
      </div>
      <div class="form-group">
        <label for="password"> {{ 'connexion.motdepasse' | translate }} </label>
        <input
          type="password"
          class="form-control"
          name="password"
          #passwordInput="ngModel"
          ngModel
          required
          [ngClass]="{ invalidInput: passwordInput.errors?.['required'] && (passwordInput?.dirty || passwordInput?.touched) }"
        />
      </div>
      <div class="messages row d-flex justify-content-between">
        <div class="col-auto">
          <div class="error-text" *ngIf="incorrectPassword">
            {{ 'connexion.incorrectMsg' | translate }}
          </div>
        </div>
        <a class="forget-text col-auto" routerLink="/forgottenPassword">
          {{ 'connexion.oublie' | translate }}
        </a>
      </div>

      <button class="btn btn-success mt-4" type="submit" [disabled]="f.invalid">
        {{ 'connexion.connexion' | translate | uppercase }}
      </button>
    </form>
  </div>
</div>
