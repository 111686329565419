<div class="d-flex main-content">
  <div class="col-10 col-sm-8 col-lg-6 col-xl-5 form-box">
    <h2>{{ 'passOublie.titre' | translate }}</h2>
    <div class="description-text">
      {{ 'passOublie.description' | translate }}
    </div>
    <form (ngSubmit)="onAskNewPassword(f)" #f="ngForm">
      <div class="form-group">
        <label for="email"> {{ 'passOublie.email' | translate }} </label>
        <input
          type="email"
          class="form-control"
          name="email"
          #emailInput="ngModel"
          ngModel
          required
          email
          [ngClass]="{ invalidInput: (emailInput.errors?.['required'] || emailInput.invalid)&& (emailInput?.dirty || emailInput?.touched) }"
        />
      </div>
      <div *ngIf="sendEmailError" class="error-text">
        {{ 'passOublie.erreur' | translate }}
      </div>

      <button class="btn btn-success mt-4" type="submit" [disabled]="f.invalid">
        {{ 'passOublie.envoyer' | translate | uppercase }}
      </button>
    </form>
  </div>
</div>
