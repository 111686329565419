<div id="langParent" [ngClass]="{ opened: isOpen }">
  <div class="menu-bg d-flex flex-column">
    <div class="mt-auto flex-column">
      <div
        *ngFor="let lang of langs"
        [ngClass]="{
          selectedLang: lang.selected,
          'd-none': !isOpen && !lang.selected,
          'col-12': true
        }"
      >
        <img
          [src]="'assets/Lang/' + lang.image"
          (click)="onSelectLang(lang.name)"
        />
      </div>
      <div class="fleche col-12" (click)="onDisplay()">
        <img src="assets/Lang/Fleche.png" />
      </div>
    </div>
  </div>
</div>
