import { Component, ElementRef, OnInit, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiManagerService } from '../services/api-manager.service';
import { AuthService } from '../services/auth.service';
import { ManagerService } from '../services/manager.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  closeResult = '';
  modaleProfile!: NgbModalRef;
  saveError: boolean = false;

  constructor(
    public authService: AuthService,
    public manager: ManagerService,
    private modalService: NgbModal,
    private api: ApiManagerService
  ) {}

  ngOnInit(): void {}

  open(content: TemplateRef<ElementRef>) {
    this.modaleProfile = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
  }

  onSavePassword(form: NgForm) {
    this.api
      .UpdatePassword(this.manager.currentUser.email, form.value['password'])
      .then((res) => {
        if (res.updated == '1' && res.passwordEncrypted) {
          this.saveError = false;
          this.authService.saveNewPassword(res.passwordEncrypted);
          this.modaleProfile.close();
        } else {
          this.saveError = true;
        }
      });
  }
}
