import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ConnexionComponent } from './connexion/connexion.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app.routing.modue';
import { AuthGuard } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { FormsModule } from '@angular/forms';
import { ApiManagerService } from './services/api-manager.service';
import { CookieService } from 'ngx-cookie-service';
import { LangMenuComponent } from './lang-menu/lang-menu.component';
import { NavComponent } from './nav/nav.component';
import { NgbModule, NgbTimepicker } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { StatsComponent } from './stats/stats.component';
import { EventComponent } from './event/event.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RangeDatePickerComponent } from './range-date-picker/range-date-picker.component';

@NgModule({
  declarations: [
    AppComponent,
    ConnexionComponent,
    HomeComponent,
    LangMenuComponent,
    NavComponent,
    StatsComponent,
    EventComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    RangeDatePickerComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgbModule,
    DataTablesModule,
    ColorPickerModule,
  ],
  providers: [AuthGuard, AuthService, ApiManagerService, CookieService],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
