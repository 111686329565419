import { Component, ElementRef, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { ApiManagerService } from '../services/api-manager.service';
import { UtilsService } from '../services/utils.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  sendEmailError: boolean = false;
  constructor(
    private router: Router,
    private utils: UtilsService,
    private api: ApiManagerService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}

  onAskNewPassword(form: NgForm) {
    this.sendEmailError = false;
    const resetKey = this.utils.GenerateResetKey();

    forkJoin([
      this.translate.get('passOublie.emailObjet'),
      this.translate.get('passOublie.emailBody', { resetKey: resetKey }),
    ]).subscribe((trads) => {
      this.api.SetResetKey(form.value['email'], resetKey).then((resp) => {
        if (resp.updated == '1') {
          this.api
            .SendEmailResetKey(
              form.value['email'],
              resetKey,
              trads[0],
              trads[1]
            )
            .then((res) => {
              if (res.sent) {
                this.router.navigateByUrl('/');
              } else {
                this.sendEmailError = true;
              }
            });
        } else {
          // Retry
          this.onAskNewPassword(form.value['email']);
        }
      });
    });
  }
}
