<nav class="navbar justify-content-between">
  <div class="nav-logo col-2">
    <a routerLink="/"><img src="assets/dreamtronic-logo.png" /></a>
  </div>
  <div class="nav-title col-10 d-flex justify-content-between">
    <div class="title">PHOTOCALL</div>
    <button
      *ngIf="authService.isAuth"
      class="user-button"
      (click)="open(modaleProfile)"
    >
      {{
        manager.currentUser.firstname.charAt(0) +
          manager.currentUser.lastname.charAt(0) | uppercase
      }}
    </button>
  </div>
</nav>

<!-- MODALE -->
<ng-template #modaleProfile let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'profil.profil' | translate }}
      <span
        >({{
          manager.currentUser.firstname + ' ' + manager.currentUser.lastname
        }})</span
      >
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <form (ngSubmit)="onSavePassword(f)" #f="ngForm">
    <div class="modal-body mt-4">
      <h5>{{ 'profil.changerPwd' | translate }}</h5>
      <hr />
      <div class="mb-4 mt-4">
        <label for="password">{{ 'profil.nouveauPwd' | translate }} :</label>

        <input
          id="password"
          type="password"
          class="form-control"
          name="password"
          ngModel
          required
        />

        <label for="passwordConfirm"
          >{{ 'profil.nouveauPwdConfirm' | translate }} :</label
        >

        <input
          id="passwordConfirm"
          class="form-control"
          name="passwordConfirm"
          type="password"
          #passwordConfirm="ngModel"
          ngModel
          required
          [ngClass]="{
            invalidInput:
              f.value['password'] !== f.value['passwordConfirm'] &&
              (passwordConfirm?.dirty || passwordConfirm?.touched)
          }"
        />

        <div class="error-message" *ngIf="saveError">
          {{ 'profil.erreur' | translate }}
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="submit"
        class="btn btn-success"
        [disabled]="
          f.invalid || f.value['password'] !== f.value['passwordConfirm']
        "
      >
        {{ 'profil.enregistrer' | translate }}</button
      ><button
        type="button"
        class="btn btn-danger"
        (click)="authService.signOut(); modal.close()"
      >
        {{ 'profil.deconnexion' | translate }}
      </button>
    </div>
  </form>
</ng-template>
