import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiManagerService } from './api-manager.service';
import { ManagerService } from './manager.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isAuth = false;
  email!: string;
  password!: string;

  constructor(
    private api: ApiManagerService,
    private router: Router,
    private cookieService: CookieService,
    private manager: ManagerService,
    private location: Location
  ) {}

  signIn(email?: string, password?: string): Promise<boolean> {
    if (email && password) {
      this.email = email;
      this.password = password;
    } else {
      this.getCredentialsFromCookies();
    }

    return new Promise((resolve) => {
      this.api
        .CheckCredentials(
          this.email,
          this.password,
          email && password ? false : true
        )
        .then((respServer) => {
          if (respServer.valid == 1) {
            this.password = respServer.passwordEncrypted;
            this.saveCredentials();

            this.manager.initAccount(this.email).then((initialized) => {
              if (initialized) {
                this.isAuth = true;
                if (this.location.path() == '/auth') {
                  this.router.navigateByUrl('/');
                }

                resolve(this.isAuth);
              }
            });
          } else {
            this.isAuth = false;
            this.deleteCredentials();
            resolve(this.isAuth);
          }
        });
    });
  }

  signOut() {
    this.isAuth = false;
    this.email = '';
    this.password = '';
    this.deleteCredentials().then(() => {
      setTimeout(() => {
        this.router.navigateByUrl('/auth');
      }, 500);
    });
  }

  saveCredentials() {
    this.cookieService.set('email', this.email, { expires: 30, path: '/' });
    this.cookieService.set('password', this.password, {
      expires: 30,
      path: '/',
    });
  }

  getCredentialsFromCookies() {
    this.email = this.cookieService.get('email');
    this.password = this.cookieService.get('password');
  }

  deleteCredentials(): Promise<boolean> {
    return new Promise((resolve) => {
      this.cookieService.delete('email', '/');
      this.cookieService.delete('password', '/');
      resolve(true);
    });
  }

  saveNewPassword(newPass: string) {
    this.password = newPass;
    this.saveCredentials();
  }
}
